<template>
  <v-container>
    <!-- page header -->
    <v-row>
      <v-col cols="12" sm="6">
        <h1 class="display-1">Edit User</h1>
        <p class="body-1">{{ userAccount.email }}</p>
      </v-col>
      <v-col cols="12" md="6" align="end">
        <v-btn color="primary" depressed to="/users" exact>
          Back to Users</v-btn
        >
      </v-col>
    </v-row>
    <!-- page header -->

    <!-- row 1 -->
    <v-row>
      <v-col cols="12">
        <v-card outlined :loading="loading">
          <v-card-text class="text--primary">
            <v-row>
              <!-- user account info -->
              <v-col cols="12" md="4" v-if="userAccount">
                <h3 class="mb-3 title">User Information</h3>
                <ul>
                  <li>
                    Name: {{ userAccount.firstName }} {{ userAccount.lastName }}
                  </li>
                  <li v-if="userAccount.email">
                    <v-btn
                      small
                      text
                      color="primary"
                      class="px-0"
                      @click="copyText(userAccount.email)"
                    >
                      Email: {{ userAccount.email }}
                      <v-icon right small>mdi-content-copy</v-icon></v-btn
                    >

                    <v-btn small text color="primary" @click="dialog = true"
                      ><v-icon>mdi-square-edit-outline</v-icon></v-btn
                    >
                  </li>

                  <!-- edit email pop up -->
                  <div v-if="dialog">
                    <v-dialog
                      v-model="dialog"
                      width="30%"
                      transition="dialog-bottom-transition"
                    >
                      <v-card tile>
                        <!-- dialog toolbar -->
                        <v-toolbar
                          dark
                          color="primary"
                          dense
                          flat
                          style="
                            position: sticky;
                            top: 0;
                            width: 100%;
                            z-index: 500;
                          "
                        >
                          <v-toolbar-title>
                            <strong>Update Email Address</strong>
                            <span class="ml-6"> </span>
                          </v-toolbar-title>
                          <v-spacer />
                          <v-toolbar-items>
                            <v-btn
                              depressed
                              color="primary"
                              dark
                              :loading="loading"
                              :disabled="loading"
                              @click="dialog = false"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text class="text--primary mt-6">
                          <!-- form -->
                          <v-form ref="form" @submit.prevent="saveEmail">
                            <v-row style="justify-content: center">
                              <v-col cols="9">
                                <v-text-field
                                  v-model="email"
                                  :loading="loading"
                                  :disabled="loading"
                                  label="Enter Email Address"
                                  :rules="emailRules"
                                  required
                                  hide-details="auto"
                                ></v-text-field> </v-col
                            ></v-row>
                            <v-row style="justify-content: center">
                              <v-col cols="3">
                                <v-btn
                                  depressed
                                  :loading="loading"
                                  color="primary"
                                  :disabled="!isEmailValid || emailSaving"
                                  style="width: inherit"
                                  @click="saveEmail"
                                >
                                  Submit
                                </v-btn>
                              </v-col></v-row
                            ></v-form
                          ></v-card-text
                        ></v-card
                      ></v-dialog
                    >
                  </div>

                  <!-- edit email pop up -->

                  <li v-if="userAccount.secondaryEmail">
                    Secondary Email: {{ userAccount.secondaryEmail }}
                  </li>
                  <li>
                    <v-btn
                      small
                      text
                      color="primary"
                      class="px-0"
                      @click="copyText(userAccount.packageID)"
                    >
                      PUID: {{ userAccount.packageID }}
                      <v-icon right small>mdi-content-copy</v-icon></v-btn
                    >
                  </li>
                  <li>
                    Date Registered:
                    {{ formatDate(userAccount.dateRegistered) }}
                  </li>
                  <li>
                    Date Last Signed In:
                    {{ formatDate(userAccount.lastSignedIn) }}
                  </li>
                  <li>Package Code: {{ eventReportWinner.packageCode }}</li>
                  <li>Destination: {{ eventReportWinner.destination }}</li>
                  <li>
                    Number of Nights: {{ eventReportWinner.numberNights }}
                  </li>
                  <li>Unit Type: {{ eventReportWinner.unitType }}</li>
                  <li v-if="eventReport.eventReportNumber">
                    <v-btn
                      small
                      text
                      color="primary"
                      class="px-0"
                      :to="`/event-reports?q=${eventReport.id}`"
                    >
                      Event Report #: {{ eventReport.eventReportNumber }}</v-btn
                    >
                  </li>
                  <li>Event Name: {{ eventReport.eventName }}</li>
                  <li>Event Date: {{ formatDate(eventReport.eventDate) }}</li>
                  <li v-if="eventReportWinner.travelByDate">
                    <v-btn
                      small
                      text
                      color="primary"
                      class="px-0"
                      @click="travelByDateDialog = true"
                    >
                      Travel By Date:
                      {{ formatDate(eventReportWinner.travelByDate) }}</v-btn
                    >
                  </li>
                  <li v-if="userAccount.mailingAddress">Address 1: {{ userAccount.mailingAddress.address1 }}</li>
                  <li v-if="userAccount.mailingAddress">Address 2: {{ userAccount.mailingAddress.address2 }}</li>
                  <li v-if="userAccount.mailingAddress">City: {{ userAccount.mailingAddress.city }}</li>
                  <li v-if="userAccount.mailingAddress">State: {{ userAccount.mailingAddress.state }}</li>
                  <li v-if="userAccount.mailingAddress">Zip Code: {{ userAccount.mailingAddress.zipCode }}</li>
                  <li v-if="userAccount.mailingAddress">Country: {{ userAccount.mailingAddress.country }}</li>


                  <v-subheader v-if="eventReport.auctioneerUsed"
                    ><strong>Auctioneer Info:</strong></v-subheader
                  >
                  <li
                    v-if="
                      eventReport.auctioneerUsed && eventReport.auctioneerName
                    "
                  >
                    Name: {{ eventReport.auctioneerName }}
                  </li>
                  <li
                    v-if="
                      eventReport.auctioneerUsed &&
                      eventReport.auctioneerCompany
                    "
                  >
                    Company: {{ eventReport.auctioneerCompany }}
                  </li>
                  <li
                    v-if="
                      eventReport.auctioneerUsed && eventReport.auctioneerEmail
                    "
                  >
                    Email: {{ eventReport.auctioneerEmail }}
                  </li>
                </ul>
              </v-col>

              <!-- access controls -->
              <v-col cols="12" md="4">
                <h3 class="mb-3 title">Access Control</h3>
                <v-switch
                  dense
                  hide-details
                  inset
                  v-model="userAccount.canAccess"
                  label="Can Access Dashboard"
                ></v-switch>
                <v-switch
                  dense
                  hide-details
                  inset
                  v-model="userAccount.canBook"
                  label="Can Book"
                ></v-switch>
                <v-switch
                  dense
                  hide-details
                  inset
                  v-model="userAccount.canBrowseInventory"
                  label="Can Browse Inventory"
                ></v-switch>
                <v-switch
                  dense
                  hide-details
                  inset
                  v-model="userAccount.canSendMessages"
                  label="Can Send Messages"
                ></v-switch>
                <v-btn
                  color="primary"
                  @click="updateAccessControls"
                  depressed
                  class="mt-8"
                  :loading="loading"
                  :disabled="loading"
                  >Update Access Controls</v-btn
                >
              </v-col>

              <v-col cols="12" md="4">
                <v-row>
                  <!-- adminNotes -->
                  <v-col cols="12">
                    <h3 class="mb-3 title">Admin Notes</h3>
                    <v-textarea
                      outlined
                      label="Admin Notes (not seen by traveler)"
                      hide-details
                      dense
                      v-model="userAccount.adminNotes"
                    ></v-textarea>
                    <div class="d-flex">
                      <v-spacer />
                      <v-btn
                        class="ml-auto mt-4"
                        color="primary"
                        small
                        depressed
                        @click="updateAdminNotes"
                        >Add/Update Admin Notes</v-btn
                      >
                    </div>
                  </v-col>

                  <!-- traveler sign ins -->
                  <v-col cols="12" class="my-6" v-if="signIns.length">
                    <v-divider />
                  </v-col>

                  <v-col cols="12" v-if="signIns.length">
                    <p class="caption">
                      IP address recording began on 3/20/2023
                    </p>
                    <h3 class="mb-3 title">Sign Ins</h3>
                    <ul>
                      <li v-for="(item, index) in signIns" :key="index">
                        <span class="mr-6">{{ formatDate(item.date) }}</span>
                        <span>{{ item.ipAddress }}</span>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- row 1 -->

    <!-- adding message -->
    <v-fade-transition>
      <v-row v-if="addingMessage">
        <v-col cols="12">
          <v-card outlined :loading="loading">
            <v-card-text class="text--primary">
              <v-row>
                <v-col cols="12" md="6">
                  <div class="canned-header">
                    <h3 class="mb-4">Use Canned Response</h3>
                    <v-dialog v-model="dialogMessage" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          color="primary"
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="messageItem.isEditing = false"
                          :loading="loading2"
                          :disabled="loading2"
                        >
                          Add New Message
                        </v-btn>
                      </template>
                      <v-card v-if="dialogMessage">
                        <v-form ref="form" @submit.prevent="saveCannedMessage">
                          <v-card-title>
                            <span class="text-h5">{{
                              messageItem.isEditing
                                ? "Edit Message"
                                : "Add Message"
                            }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-text-field
                                v-model="messageItem.text"
                                label="Title"
                                :rules="[(v) => !!v || 'Field Required']"
                                :loading="loading2"
                                :disabled="loading2"
                              ></v-text-field>

                              <v-textarea
                                v-model="messageItem.value"
                                label="Message"
                                :rules="[(v) => !!v || 'Field Required']"
                                :loading="loading2"
                                :disabled="loading2"
                               
                              ></v-textarea>
                              <p class="hint-paragraph">
                                Hint : Please use these variables (eventName),
                                (numberNights), (travelByDate), (firstName),
                                (destination), (email), (packageID),
                                (packageCode), (nextYear), (expDate) to insert
                                the information dynamically.
                              </p>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="dialogMessage = false"
                              :loading="loading2"
                              :disabled="loading2"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveCannedMessage"
                              :loading="loading2"
                              :disabled="loading2"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </div>
                  <v-autocomplete
                    outlined
                    v-model.trim="cannedResponse"
                    :items="cannedResponses"
                    dense
                    @change="insertCannedResponse"
                    label="Type/Select Canned Response"
                  >
                    <template #item="{ item }">
                      <div class="option-container">
                        <span>{{ item.text }}</span>
                        <div class="actions-canned">
                          <v-btn
                            icon
                            fab
                            x-small
                            @click="editCannedResponse(item, $event)"
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            fab
                            x-small
                            @click="deleteCannedResponse(item, $event)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Are you sure you want to delete this
                      message?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogDelete = false"
                        :loading="loading2"
                        :disabled="loading2"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        :loading="loading2"
                        :disabled="loading2"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-col cols="12" md="6">
                  <h3 class="mb-4">Add Message</h3>
                  <v-form ref="newMessageForm">
                    <v-textarea
                      dense
                      outlined
                      :rows="8"
                      v-model.trim="message.message"
                      :loading="loading"
                      :disabled="loading"
                      label="Message Content *"
                      :rules="[(v) => !!v || 'Field Required']"
                    ></v-textarea>
                  </v-form>
                  <div class="d-flex">
                    <v-btn @click="addingMessage = false" outlined color="error"
                      >cancel</v-btn
                    >
                    <v-spacer />
                    <v-btn color="primary" depressed @click="sendNewMessage"
                      >Send Message</v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>
    <!-- adding message -->

    <!-- timeline -->
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="!addingMessage && timeline && timeline.length"
              color="primary"
              outlined
              @click="markAllMessagesRead"
              >Mark All Messages Read</v-btn
            >
            <v-btn
              v-if="!addingMessage"
              color="primary"
              depressed
              @click="addingMessage = !addingMessage"
              >Add Message</v-btn
            >
          </v-card-actions>
          <v-card-text class="text--primary">
            <h3 class="title text-center mb-4">Timeline</h3>
            <v-timeline :dense="$vuetify.breakpoint.smAndDown">
              <v-timeline-item
                v-for="(item, index) in timeline"
                :key="index"
                :icon="item.icon"
                :color="item.color"
              >
                <v-card
                  elevation="1"
                  :outlined="$vuetify.theme.dark ? true : false"
                  :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                >
                  <v-card-title class="headline">
                    {{ item.title }}
                  </v-card-title>
                  <v-card-text class="text--primary">
                    <p>
                      Date:
                      {{ formatDate(item.dateCreated) }}
                    </p>
                    <p v-if="item.message">Message: {{ item.message }}</p>
                  </v-card-text>
                  <v-card-actions
                    v-if="item.message.includes('purchase')"
                    class="d-none"
                  >
                    <v-btn
                      text
                      :loading="loading"
                      :disabled="loading"
                      color="error"
                      class="ml-auto mt-4 d-block"
                      >Email Your Travel Details to Yourself</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- timeline -->

    <!-- travelByDate dialog -->
    <v-dialog v-model="travelByDateDialog" width="290px" persistent>
      <v-card :loading="loading" :disabled="loading">
        <v-date-picker scrollable v-model.trim="eventReportWinner.travelByDate">
        </v-date-picker>
        <v-card-actions>
          <v-btn
            text
            @click="
              travelByDateDialog = false;
              eventReportWinner.travelByDate = eventReport.travelByDate;
            "
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn @click="updateTravelByDate" color="primary" depressed
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- travelByDate dialog -->
  </v-container>
</template>

<script>
import { portalsApp } from "@/firebase";
import firebase from "@/plugins/firebase";
import axios from "axios";
import formatDate from "@/mixins/formatDate";
import moment from "moment";
import copyText from "@/mixins/copyText";
export default {
  mixins: [formatDate, copyText],
  firestore: {
    retailPackages: portalsApp.firestore().collection("retailPackages"),
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    const cannedMessage = portalsApp.firestore().collection("cannedMessage");
    // bind userAccount
    await this.$bind(
      "userAccount",
      portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.$route.params.id)
    );

    // bind eventReport
    await this.$bind(
      "eventReport",
      portalsApp
        .firestore()
        .collection("eventReports")
        .doc(this.userAccount.eventReportID)
    );

    // bind messages
    await this.$bind("cannedDbMessages", cannedMessage);

     const mps = portalsApp
      .firestore()
      .collection("mps")
      .where("puid", "==", this.userAccount.packageID);
    // bind mps
    await this.$bind("mps", mps);

    // filter eventReport winner
    this.eventReportWinner = this.eventReport.winners.filter(
      (w) => w.packageID == this.userAccount.packageID
    )[0];

    await this.$store.dispatch("setLoading", false);
  },
  data: () => ({
    loading2: false,
    cannedDbMessages: [],
    dialogDelete: false,
    dialogMessage: false,
    dialog: false,
    messageItem: {
      isEditing: false,
      text: "",
      value: "",
    },
    emailSaving: false,
    email: "",
    oldEmail: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    travelByDateDialog: false,
    cannedResponse: "",
     mps: "",
    addingMessage: false,
    message: {
      adminActionRequired: false,
      archived: false,
      dateCreated: new Date().toISOString().substring(0, 10),
      from: "admin",
      message: "",
      readByAdmin: true,
      repliedToByAdmin: true,
      senderActionRequired: false,
      title: "We sent you a message",
      to: "",
      icon: "mdi-message-reply-text-outline",
      color: "primary",
    },
    userAccount: {
      termsAndConditions: {
        agreed: "",
        title: "",
        content: "",
        id: "",
      },
    },
    eventReport: "",
    eventReportWinner: "",
    retailPackages: [],
  }),
  watch: {
    dialog(newValue, oldValue) {
      if (newValue) this.email = "";
    },
    dialogMessage(newValue) {
      if (!newValue)
        this.messageItem = { isEditing: false, text: "", value: "" };
    },
  },
  computed: {
    isEmailValid() {
      return this.emailRules.every((rule) => rule(this.email) === true);
    },
    loading() {
      return this.$store.state.loading;
    },
    timeline() {
      if (!this.userAccount) return;
      if (!this.userAccount.timeline) return;
      return this.userAccount.timeline.reverse();
    },
    nextYear() {
      // get today, starting with the year
      let todayThisYear = moment().startOf("year");
      // add 1 year to today
      let nextYear = todayThisYear.add("1", "year").format("YYYY");
      return nextYear;
    },
    cannedResponses() {
      return this.constructMessages(this.cannedDbMessages);
    },
    signIns() {
      if (!this.userAccount) return [];
      if (!this.userAccount.signIns) return [];

      return this.userAccount.signIns;
    },
  },
  methods: {
    constructMessages(messages) {
      if (!messages) return [];

      const replaceFrom = [
        {
          old: "(eventDate)",
          new: this.formatDate(this.eventReport.eventDate),
        },
        {
          old: "(numberNights)",
          new: this.eventReportWinner.numberNights,
        },
        {
          old: "(travelByDate)",
          new: this.formatDate(this.eventReportWinner.travelByDate),
        },
        { old: "(firstName)", new: this.userAccount.firstName },
        { old: "(destination)", new: this.eventReportWinner.destination },
        { old: "(email)", new: this.userAccount.email },
        { old: "(packageID)", new: this.eventReportWinner.packageID },
        { old: "(packageCode)", new: this.eventReportWinner.packageCode },
         { old: "(nextYear)", new: this.nextYear },
        {
          old: "(expDate)",
          new:
            this.mps.length && this.mps[0].originalExpiryDate
              ? this.mps[0].originalExpiryDate
              : " ",
        },
      ];
      const updatedMessages = messages.map((el) => {
        let msg = { ...el };
        replaceFrom.map((textFrom) => {
          msg.text = msg.text.replaceAll(textFrom.old, textFrom.new).trim();
          msg.value = msg.value.replaceAll(textFrom.old, textFrom.new).trim();
        });
        return msg;
      });
      return updatedMessages;
    },
    async deleteItemConfirm() {
      this.loading2 = true;
      await portalsApp
        .firestore()
        .collection("cannedMessage")
        .doc(this.messageItem.id)
        .delete();
      this.dialogDelete = false;
      this.loading2 = false;
    },
    async saveCannedMessage() {
      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      this.loading2 = true;

      let data = this.messageItem;
      if (data.isEditing) {
        delete data.isEditing;
        await portalsApp
          .firestore()
          .collection("cannedMessage")
          .doc(data.id)
          .update(data);

        this.loading2 = false;
        this.dialogMessage = false;
      } else {
        delete data.isEditing;
        await portalsApp.firestore().collection("cannedMessage").add(data);

        this.loading2 = false;
        this.dialogMessage = false;
      }
    },
    editCannedResponse(item, event) {
      event.stopPropagation();
      this.messageItem = this.cannedDbMessages.find((el) => el.id === item.id);
      this.messageItem.isEditing = true;
      this.dialogMessage = true;
    },
    deleteCannedResponse(item, event) {
      event.stopPropagation();
      this.messageItem = this.cannedDbMessages.find((el) => el.id === item.id);
      this.dialogDelete = true;
    },
    async saveEmail() {
      this.oldEmail = this.userAccount.email;
      this.emailSaving = true;
      await this.$store.dispatch("setLoading", true);

      let users = portalsApp
        .firestore()
        .collection("userAccounts")
        .where("email", "==", this.email);
      let usersData = await users.get();
      if (usersData.docs.length) {
        this.loading = false;
        this.dialog = false;
        this.email = "";
        this.emailSaving = false;
        await this.$store.dispatch("setLoading", false);
        // success snackbar
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Email already exist",
          timeout: 5000,
        });
      } else {
        // api code

        const updateUserEmailData = {
          uid: this.$route.params.id,
          email: this.email,
        };

        await axios
          .post("https://api.silentauctionvacations.com/updateEmail", updateUserEmailData)
          .then((res) => {
            // console.log("update Api", res);
            this.updateEmailToUserAccount();
            // this.updateEmailToBookings();
            this.sendMailToUser();
          })
          .catch((err) => {
            console.log(err);
            this.dialog = false;
            this.emailSaving = false;
            this.$store.dispatch("setLoading", false);
          });
      }
    },
    sendMailToUser() {
      axios
        .post("/api/send-email-updation", { email: this.email })
        .then((res) => {
          // Password reset email sent!
          // ..
          // console.log(res)
        })
        .catch((error) => {
          console.log(error);
          // ..
        });
    },
    async updateEmailToUserAccount() {
      const user = await portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.$route.params.id);

      this.userAccount.email = this.email;
      this.userAccount.oldEmail = this.oldEmail;

      const res = await user
        .update(this.userAccount)
        .then((res) => {
          // Handle success response
          this.$toast.success(`Email Updated successfully!`, {
            timeout: 2000,
          });
          this.dialog = false;
          this.emailSaving = false;
          this.$store.dispatch("setLoading", false);
        })
        .catch((error) => {
          // Handle error response
          this.$toast.error(`Something went wrong!`, {
            timeout: 2000,
          });
          this.emailSaving = false;
          this.$store.dispatch("setLoading", false);
        });
    },
    async updateEmailToBookings() {
      const bookingRef = portalsApp
        .firestore()
        .collection("bookingsConfirmations")
        .where("email", "==", this.oldEmail);

      let bookings = await bookingRef.get();

      if (bookings.docs.length) {
        bookings = bookings.docs.map((i) => i.data());
        // console.log("array of bookings", bookings);
        for (let index = 0; index < bookings.length; index++) {
          const element = bookings[index];
          let emailNew = this.email;
          // console.log("element1", element);
          await portalsApp
            .firestore()
            .collection("bookingsConfirmations")
            .doc(element.id)
            .update({ email: emailNew });

          // console.log("element",el.id)
        }
      }
    },
    // viewEventReport() {
    //   let number = this.eventReport.eventReportNumber;
    //   if (!number) return;
    //   portalsApp
    //     .firestore()
    //     .collection("eventReports")
    //     .where("eventReportNumber", "==", number)
    //     .get()
    //     .then((snap) => {
    //       if (!snap.docs.length) return;
    //       snap.docs.forEach((doc) => {
    //         let data = doc.data();
    //         let eventReportNumber = data.eventReportNumber;
    //         if (!eventReportNumber) return;
    //         if (eventReportNumber != number) return;
    //         let id = data.id;
    //         this.$router.push(`/event-reports/${id}`);
    //       });
    //     });
    // },
    // copyEmailAddress() {
    //   if (this.userAccount.email == "") return;
    //   navigator.clipboard.writeText(this.userAccount.email).then(() => {
    //     // success snackbar
    //     this.$store.dispatch("setSnackbar", {
    //       display: true,
    //       color: "success",
    //       text: `${this.userAccount.email} copied to clipboard!`,
    //       timeout: 5000,
    //     });
    //   });
    // },
    async updateTravelByDate() {
      await this.$store.dispatch("setLoading", true);

      // get event report
      let eventReportDocRef = portalsApp
        .firestore()
        .collection("eventReports")
        .doc(this.userAccount.eventReportID);
      let eventReportDoc = await eventReportDocRef.get();
      let eventReportData = eventReportDoc.data();

      // isolate winner
      let winners = eventReportData.winners;
      winners.forEach((i) => {
        if (i.packageID == this.userAccount.packageID) {
          i.travelByDate = this.eventReportWinner.travelByDate;
        }
      });

      // update winners doc
      await eventReportDocRef.update({ winners });

      // reset form and UI
      await this.$store.dispatch("setLoading", false);
      this.travelByDateDialog = false;

      // success snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Travel by date updated",
        timeout: 5000,
      });
    },
    async markAllMessagesRead() {
      await this.$store.dispatch("setLoading", true);

      // userAccount firestore doc
      let userAccountRef = portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id);

      // extracting userAccount Data
      let userAccountData = await userAccountRef.get();
      userAccountData = userAccountData.data();

      // mark all timeline messages are read
      let timeline = userAccountData.timeline;
      timeline.forEach((i) => {
        i.readByAdmin = true;
        i.adminActionRequired = false;
        i.repliedToByAdmin = true;
      });

      // update timeline as all read
      await userAccountRef.update({
        timeline: timeline,
      });

      // success snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "All messages marked as read",
        timeout: 5000,
      });

      // reset form and UI
      await this.$store.dispatch("setLoading", false);
      this.addingMessage = false;
      return this.$router.push("/messages");
    },
    async updateAdminNotes() {
      if (!this.userAccount.adminNotes || !this.userAccount.adminNotes.length)
        return;

      await this.$store.dispatch("setLoading", true);

      // userAccount firestore doc
      let userAccount = portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id);

      // update userAccount adminNotes
      await userAccount.update({
        adminNotes: this.userAccount.adminNotes,
      });

      await this.$store.dispatch("setLoading", false);

      // success snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Admin notes updated successfully",
        timeout: 5000,
      });
    },
    async sendNewMessage() {
      if (!this.$refs.newMessageForm.validate()) return;
      if (!this.message.message.length) return;
      await this.$store.dispatch("setLoading", true);

      // setting "to" email
      this.message.to = this.userAccount.email;

      // if wanting to transfer their package
      if (this.cannedResponse.includes("transfer"))
        this.message.senderActionRequired = true;

      // userAccount firestore doc
      let userAccount = portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id);

      // add timeline item to userAccount firestore doc
      await userAccount.update({
        timeline: firebase.firestore.FieldValue.arrayUnion(this.message),
      });

      // get userAccount firestore doc
      userAccount = await userAccount.get();
      userAccount = userAccount.data();
      let timeline = userAccount.timeline;

      // setting all timeline items/messages as seen/dealt with by admin
      timeline.forEach((i) => {
        i.readByAdmin = true;
        i.adminActionRequired = false;
        i.repliedToByAdmin = true;
      });

      // userAccount firestore doc
      userAccount = portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.userAccount.id);

      // update timeline items as all read, replied to, etc
      await userAccount.update({
        timeline,
      });

      // send new message in your traveler portal dashboard email to traveler
      await axios.post("/api/new-dashboard-message", {
        email: this.userAccount.email,
        secondaryEmail: this.userAccount.secondaryEmail,
      });

      // reset form and UI
      this.$refs.newMessageForm.reset();
      this.addingMessage = false;

      // success snackbar
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "Message sent successfully",
        timeout: 5000,
      });

      await this.$store.dispatch("setLoading", false);
      this.$router.push("/messages");
    },
    insertCannedResponse() {
      this.$refs.newMessageForm.reset();
      this.message.message = this.cannedResponse;
    },
    async updateAccessControls() {
      await this.$store.dispatch("setLoading", true);
      this.userAccount.dateUpdated = new Date().toISOString().substring(0, 10);
      await portalsApp
        .firestore()
        .collection("userAccounts")
        .doc(this.$route.params.id)
        .update(this.userAccount);
      await this.$store.dispatch("setLoading", false);
      await this.$store.dispatch("setSnackbar", {
        display: true,
        color: "success",
        text: "User updated successfully",
        timeout: 5000,
      });
    },
  },
};
</script>
<style>
.option-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: -webkit-fill-available;
}

.option-container span {
  flex-grow: 1;
  margin-right: 10px;
}
.canned-header {
  display: flex;
  justify-content: space-between;
}
	.hint-paragraph {
  font-size: smaller;
  line-height: initial;
  color: #595a5c;
}
</style>
